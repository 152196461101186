<template>

  <div>
   <div class="sharebet-header-bg text-center">
     <div class="text-center">
      <img src="/img/sharebet/title.png" alt="" style="width:100%">
     </div>
     <div class="leader-board-text text-center">
        <span class="text-heavy">Leaderboard</span><br>
        <span class="">Ends 30th May 2022</span>

     </div>

     <div class="row m-0 p-0 pb-4">
       <div class="col-4 p-0 text-center">
         <img src="/img/sharebet/medal2.png" alt=""> <br>
          <span class="text-heavy-2 lowercase">{{ players[1].first_name.String.toLowerCase() }}</span><br>
          <span class="leader-amount">KSH 160,000</span>
          
       </div>
        <div class="col-4 p-0 text-center">
         <img src="/img/sharebet/medal1.png" alt=""><br>
          <span class="text-heavy-2 lowercase">{{ players[0].first_name.String.toLowerCase() }}</span><br>
           <span class="leader-amount">KSH 300,000</span>
       </div>
        <div class="col-4 p-0 text-center">
         <img src="/img/sharebet/medal3.png" alt=""><br>
          <span class="text-heavy-2 lowercase">{{ players[2].first_name.String.toLowerCase() }}</span><br>
           <span class="leader-amount">KSH 110,000</span>
       </div>
     </div>
   </div>
    <div class="p-0 m-0 sharebet-header row text-light">
      <div class="d-flex col-4">
        <div class="p-2">#</div>
        <div class="p-2">Name</div>
      </div>
      <div class="p-2 col-4 text-center">Mobile No.</div>
      <div class="p-2 col-4 text-right">Bets (%)</div>
    </div>
    <div v-for="(p,index) in players" v-bind:key="getKey(index)" class="p-0 m-0 sharebet-header row text-light">
      <div class="d-flex col-4">
        <div class="p-2">{{ index + 1 }}</div>
        <div class="p-2">{{ p.first_name.String }}</div>
      </div>
      <div class="p-2 col-4 text-center">{{ p.msisdn.String.substring(0,4) + "****" + p.msisdn.String.substr(9) }}</div>
      <div class="p-2 col-4 text-right">{{ p.percentage }}%</div>
    </div>
  </div>

</template>

<script>
import axios from "@/services/api";
export default {
  name: 'Offers',  
  components: {
  },
   data: function () {
    return {
      players: []
    }
   },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","sharebet");
    this.fetchSummary()

  },
  methods: {
        getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'unique-id-' + index + '-');

    },
    fetchSummary(){
      var vm = this;
      var path = process.env.VUE_APP_URL_SHARE_SUMMARY;
            axios.get(path,{
        headers: {
          
        },
      })
          .then(res => {

            var msg = res.data.message;
            console.log(msg)
            this.players = msg

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {
                this.$toast.open({
                message: 'Your session on this device has expired',
                type: 'error',
                position: 'top'
              });
                vm.logout();
                return;
              } else {
                this.$toast.open({
                message: err.response.data.message,
                type: 'error',
                position: 'top'
              });
                console.log(JSON.stringify(err.response.data.message));
              }

            }
            else if (err.request) {

              console.log(JSON.stringify(err.request));

            }
            else {

              console.log(JSON.stringify(err));

            }

          })
    }
  }

}
</script>